
import React from "react";

export const LoadingMessage = () => {
  return (
    <div>
        <p className="text-[medium] m-[0.5em]">Initialising Stream...</p>
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 30 5">
            <circle fill="#fff" stroke="none" cx="10" cy="2.5" r="1">
            <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.1"
            />
            </circle>
            <circle fill="#fff" stroke="none" cx="15" cy="2.5" r="1">
            <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.2"
            />
            </circle>
            <circle fill="#fff" stroke="none" cx="20" cy="2.5" r="1">
            <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.3"
            />
            </circle>
        </svg>
        </div>
  );
};
