import React from "react";
import { PlayIcon } from '@heroicons/react/24/solid'

export const PlayButton: React.FC<React.HTMLProps<HTMLButtonElement>> = ({onClick}: React.HTMLProps<HTMLButtonElement>) => {
  return (

      <button
      // hover:bg-[#e3520533] hover:border-[#e35205]
        className="m-auto w-24 h-24 border-2 border-[#e35205] shadow-inner bg-transparent hover:border-[#e35205] hover:shadow-[inset_0_0_30px_30px_#e3520533] active:scale-100 active:shadow-[inset_0_0_30px_30px_#e3520599] focus:outline-none rounded-full playButton"
        onClick={onClick}
      >
        <div className="m-auto w-8 h-8">
          <PlayIcon/>
        </div>
      </button>
  );
};
