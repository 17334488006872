import React, { useState, Dispatch, SetStateAction, useCallback, useRef, useEffect} from "react";
import clientConfig from "../../client.json";

const client: ClientJson = clientConfig as ClientJson;

class ClientJson {
  passwords? : Array<string>
}

interface LoginProps{
  setLoginState: Dispatch<SetStateAction<LoginState>>
}

export enum LoginState{
  success,
  failed,
  start
}

export const LoginComponent: React.FC<LoginProps> = ({setLoginState}: LoginProps) => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [thisLoginState, setThisLoginState] = useState(LoginState.start);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setThisLoginState(LoginState.start)
  };

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const onLoginClick = () => {
    const loadedPasswords = client.passwords
    if(loadedPasswords?.includes(password)){
      setThisLoginState(LoginState.success)
      setTimeout(() => {
        setLoginState(LoginState.success)
      }, 200);

    } else {
      setLoginState(LoginState.failed)
      setThisLoginState(LoginState.failed)
    }
  }

  const handleKeyPress = useCallback((event : KeyboardEvent) => {
    if (event.key === 'Enter') {
      buttonRef.current?.click();
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <div className={"w-80 h-full flex flex-col space-y-10 justify-center content-center animate-fade-in-up " + 
      ((thisLoginState === LoginState.success) ?  "animate-fade-out-up" : "animate-fade-in-up")}>
      <div className="pt-8">
        <div className="relative">
          <div className="absolute right-3 top-1/3 z-50">
          <input id="showPasswordCheckbox" className="hidden" type="checkbox" checked={showPassword} onChange={toggleShowPassword} />
          <label htmlFor="showPasswordCheckbox">
            {showPassword ?
              <svg className="shrink-0 size-5" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path className="hs-password-active:block" stroke="#e35205"  d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                <circle className="hs-password-active:block" stroke="#e35205"  cx="12" cy="12" r="3"></circle>
              </svg> :
              <svg className="shrink-0 size-5" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path className="hs-password-active:block" d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                <path className="hs-password-active:block" d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                <path className="hs-password-active:block" d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                <line className="hs-password-active:block" x1="2" x2="22" y1="2" y2="22"></line>
              </svg>}
          </label>
          </div>

          <input type={showPassword ? 'text' : 'password'} onChange={handlePasswordChange} id="outlined_success" aria-describedby="outlined_success_help" className="flex transition-all duration-100 w-full border-opacity-40 hover:border-opacity-70 focus:border-opacity-100 bg-opacity-20 hover:bg-opacity-30 focus:bg-opacity-30 pl-2.5 pr-9 pb-2.5 pt-5 text-md text-white bg-neutral-100 border-2 border-[#ffffffaa] hover:bg-gradient-to-r hover:from-[#e3520533] hover:to-[#e3520500] focus:outline-none focus:ring-0 focus:border-[#e3520555] peer rounded-xl" placeholder=" " />
          <label htmlFor="outlined_success" className="absolute text-md text-[#ffffff] peer-focus:text-[#ffffff] duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-3 peer-placeholder-shown:top-1/2 peer-focus:top-3 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Password</label>
        </div>
        {thisLoginState === LoginState.failed && <p id="outlined_success_help" className="px- mt-2 text-xs text-red-500">Password Incorrect.</p>}
      </div>
      <button ref={buttonRef} onClick={onLoginClick} className="transition-all duration-75 m-auto w-28 h-14 border-2 border-neutral-100/50 shadow-inner bg-neutral-400/50 hover:bg-[#e3520533] hover:border-[#e35205] rounded-2xl">
        Continue
      </button>
    </div>
  );
};
