import React, { useState } from "react";
import { PlayButton } from "../PlayButton/PlayButton";
import { LoadingMessage } from "../LoadingMessage/LoadingMessage";
import { LoginComponent, LoginState} from "../LoginComponent/LoginComponent";

interface LaunchProps {
  logoSrc? : string;
  description?: string;
  subheading?: string;
  title?: string;
  onPlayButtonClick?: () => void;
  isLoading: boolean;
  passwords?: Array<string>;

}

export const LaunchPanel: React.FC<LaunchProps> = ({logoSrc, description, subheading, title, onPlayButtonClick, isLoading=false, passwords}: LaunchProps) => {
  const [loginState, setloginState] = useState<LoginState>(LoginState.start)

  return (
    <div style={{backdropFilter: "blur(15px)"}} className="w-[450px] h-[460px] flex flex-col items-center justify-between bg-[#e6e6e64c] backdrop-blur-lg m-auto p-[3.5em] rounded-3xl" >
      <div className="text-center space-y-2">
        {<img src={logoSrc} className="w-24 h-24 m-auto mb-7" alt="Brand Logo"/>}
        {<h1>{title}</h1>}
        {<p>{subheading}</p>}
        {<p>{description}</p>}
      </div>
      {
        (loginState === LoginState.start || loginState === LoginState.failed) && <LoginComponent setLoginState={setloginState}/>
      }
      {
        loginState === LoginState.success &&       
        <div className="flex flex-col justify-center space-y-5 opacity-0 animate-fade-in-up"> 
          {isLoading ? <LoadingMessage/> : <PlayButton onClick={onPlayButtonClick}/>}
          {isLoading ? "" : <p className="text-xl mt-3">Access your virtual site twin</p>}
        </div>
      }
    </div>
  );
};
