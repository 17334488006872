/**
 * This reference template is designed to showcase the elements used to construct your own
 * application.
 * 
 * When developing take care to:
 *  - Retain user interaction to begin audio.
 *  - Understand video sizing and mobile screen orientation.
 
 * See attached documentation for reference. Contact support@pureweb.com with any questions.
 * 
 *
 * Copyright (C) PureWeb 2020
 */

import {
  LaunchStatusEvent,
  LaunchStatusType,
  ModelDefinition,
  PlatformNext,
  UndefinedModelDefinition,
  InputEmitter,
  DefaultStreamerOptions,
  StreamerStatus,
} from "@pureweb/platform-sdk";

import {
  useStreamer,
  useLaunchRequest,
  IdleTimeout,
  LaunchRequestOptions,
  VideoStream,
  System,
} from "@pureweb/platform-sdk-react";

import * as qs from "query-string";
import React, { useState, useRef, useEffect } from "react";
import Fullscreen from "react-full-screen";
import useAsyncEffect from "use-async-effect";
import { Button, Progress, Icon } from "semantic-ui-react"
import "./App.css";
import clientConfig from "./client.json";
import logger from "./Log";
import { LaunchPanel } from "./components/LaunchPanel/LaunchPanel";


var Controller = require("./controller.js");

const client: ClientJson = clientConfig as ClientJson;

class ClientJson {
  description?: string = "description";
  subheading?: string = "subheading";
  title?: string = "title";
  logo?: string = "title";
  environmentId?: string;
  launchType?: string;
  projectId?: string;
  modelId?: string;
  version?: string;
  endpoint?: string;
  usePointerLock?: boolean;
  pointerLockRelease?: boolean;
  useNativeTouchEvents?: boolean;
  video?: string;
  steps?: Record<string, string>;
}

const humanReadableStatus = client.steps ? client.steps : {
  unknown: "(1/5) Initializing your connection",
  accepted: "(2/5) Accepted, requesting model",
  queued: "(3/5) In queue",
  requested: "(4/5) Model requested",
  ready: "(5/5) Ready, 3D session launching",
  serviced: "Viewing model",
  cancelled: "Cancelled",
  modelerror: "An issue with the model has occurred",
  unavailable: "The requested model does not exist",
};

const countSteps = ["unknown", "accepted", "queued", "requested", "ready"]

class ClientOptions {
  // Overridable connection options
  LaunchType?: string;

  // Launch queue configuration
  ProjectId?: string;
  ModelId?: string;
  Version?: string;
  EnvironmentId?: string;
  Endpoint?: string;

  // Overridable streamer options
  ForceRelay = false;
  UseNativeTouchEvents?: boolean;
  UsePointerLock?: boolean;
  PointerLockRelease?: boolean;

  isValid(): boolean {
    if (!this.ProjectId) {
      return false;
    }
    if (!this.ModelId) {
      return false;
    }
    return true;
  }
}

interface LoadingProps {
  LaunchRequestStatus: LaunchStatusEvent;
  StreamerStatus: StreamerStatus;
}

const LoadingView: React.FC<LoadingProps> = (props: LoadingProps) => {
  if (
    props.StreamerStatus === StreamerStatus.Connected ||
    props.StreamerStatus === StreamerStatus.Completed
  ) {
    return <div />;
  }

  let content;

  if (props.StreamerStatus === StreamerStatus.NotSupported) {
    content = (
      <div>
        <h3>
          Your browser does not support the necessary WebRTC capabilities.
        </h3>
      </div>
    );
  }
  if (
    props.LaunchRequestStatus.status === LaunchStatusType.Unavailable ||
    props.LaunchRequestStatus.status === LaunchStatusType.Error ||
    props.StreamerStatus === StreamerStatus.Failed
  ) {
    content = (
      <div>
        <h3>The experience is presently unavailable.</h3>
        <h3>Please refresh to request a new session.</h3>
      </div>
    );
  } else {
    // Remove background image
    document.body.style.setProperty("background-image", "none");

    content = (
      <div className="w-96">
        {/* <svg version="1.1" x="0px" y="0px" viewBox="0 0 30 5">
          <circle fill="#fff" stroke="none" cx="10" cy="2.5" r="1">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.1"
            />
          </circle>
          <circle fill="#fff" stroke="none" cx="15" cy="2.5" r="1">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.2"
            />
          </circle>
          <circle fill="#fff" stroke="none" cx="20" cy="2.5" r="1">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.3"
            />
          </circle>
        </svg> */}
        <div>
          <Progress color='grey' size="tiny" percent={((countSteps.indexOf(props.LaunchRequestStatus.status) + 1) / countSteps.length) * 100} active/>
        </div>
        
        {/* value={(steps.indexOf(props.LaunchRequestStatus.status) / steps.length) * 10} */}
        <h3>{humanReadableStatus[props.LaunchRequestStatus.status]}</h3>
      </div>
    );
  }
  return (
    <>
      <video
        className="fixed w-screen h-screen object-cover"
        src="/videos/1731624162-BeachH-Villa_RouteThrough_120s.mp4"
        width="100%"
        height="100%"
        autoPlay
        loop
        muted
      >
        <source
          src="/videos/1731624162-BeachH-Villa_RouteThrough_120s.mp4"
          type="video/mp4"
        ></source>
        <track kind="captions"></track>
      </video>
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "90%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div style={{ textAlign: "center" }}>{content}</div>
      </div>
    </>
  );
};

interface ViewProps {
  LaunchRequestStatus: LaunchStatusEvent;
  StreamerStatus: StreamerStatus;
  VideoStream: MediaStream;
  InputEmitter: InputEmitter;
  UseNativeTouchEvents: boolean;
  UsePointerLock: boolean;
  PointerLockRelease: boolean;
}
const EmbeddedView: React.FC<ViewProps> = (props: ViewProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [isFull, setIsFull] = useState(false);

  useEffect(() => {
    if (typeof videoRef.current === "undefined" || videoRef.current === null) {
      return;
    }
    videoRef.current.className = "fixed w-screen h-screen object-cover";
  }, []);

  // Fullscreen API presently supported on iPad, but not iPhone or iPod
  const isIPhone =
    System.Browser().os === "iOS" &&
    !window.navigator.userAgent.includes("iPad");
  return (
    <div className="h-full">
      <Fullscreen enabled={isFull} onChange={(isFull) => setIsFull(isFull)}>
        <IdleTimeout
          Status={props.StreamerStatus}
          WarningThreshold={300}
          ExitThreshold={120}
          WarningCallback={() => setIsFull(false)}
          ExitCallback={() => window.location.reload()} // TODO: How to 'close' a contribution?
        />

        <LoadingView
          LaunchRequestStatus={props.LaunchRequestStatus}
          StreamerStatus={props.StreamerStatus}
        />

        <VideoStream
          VideoRef={videoRef}
          Emitter={props.InputEmitter}
          Stream={props.VideoStream}
          UseNativeTouchEvents={true}
          UsePointerLock={props.UsePointerLock}
          PointerLockRelease={props.PointerLockRelease}
        />

        <Button
          onClick={() => setIsFull(true)}
          style={{ position: "absolute", top: 10, right: 10 }}
          className={
            isIPhone ||
            isFull ||
            props.StreamerStatus !== StreamerStatus.Connected
              ? "hidden"
              : ""
          }
        >
          <Icon name="expand" />
        </Button>
      </Fullscreen>
    </div>
  );
};

// Initialize audio.
// load() must be called from a user interaction, especially to retain iOS audio
// this can be 'mouseup', 'touchend' or 'keypress'
// Pass the audioStream created from useStreamer as the srcObject to play game audio.
const audio = new Audio();
audio.autoplay = true;
audio.volume = 0.5;

// Parse query parameters
const query = qs.parse(window.location.search);
const clientOptions: ClientOptions = new ClientOptions();
clientOptions.LaunchType = (query["launchType"] as string) ?? client.launchType;
clientOptions.Endpoint = (query["endpoint"] as string) ?? client.endpoint;
clientOptions.ProjectId = (query["projectId"] as string) ?? client.projectId;
clientOptions.ModelId = (query["modelId"] as string) ?? client.modelId;
clientOptions.Version = (query["version"] as string) ?? client.version;
clientOptions.EnvironmentId =
  (query["environmentId"] as string) ?? client.environmentId;
// use client json config if usePointerLock query string parameter is undefined, else use query string parameter. Default to false if non are present
clientOptions.UsePointerLock =
  (query["usePointerLock"] === undefined
    ? client.usePointerLock
    : query["usePointerLock"] === "true") ?? true;
// release the pointer lock on mouse up if true
clientOptions.PointerLockRelease =
  (query["pointerLockRelease"] === undefined
    ? client.pointerLockRelease
    : query["pointerLockRelease"] === "true") ?? true;

clientOptions.ForceRelay =
  typeof query["forceRelay"] !== "undefined" && query["forceRelay"] !== null
    ? true
    : false;
clientOptions.UseNativeTouchEvents =
  typeof query["useNativeTouchEvents"] !== "undefined" &&
  query["useNativeTouchEvents"] !== null
    ? true
    : false;
// Initialize platform reference
const platform = new PlatformNext();
platform.initialize({
  endpoint: clientOptions.Endpoint || "https://api.pureweb.io",
});

// @ts-ignore
const App: React.FC = () => {
  const [modelDefinitionUnavailable, setModelDefinitionUnavailable] =
    useState(false);
  const [modelDefinition, setModelDefinition] = useState(
    new UndefinedModelDefinition()
  );
  const [availableModels, setAvailableModels] = useState<ModelDefinition[]>();
  const streamerOptions = DefaultStreamerOptions;

  useAsyncEffect(async () => {
    if (clientOptions.ProjectId) {
      logger.info("Initializing available models: " + clientOptions.ProjectId);
      try {
        await platform.useAnonymousCredentials(
          clientOptions.ProjectId,
          clientOptions.EnvironmentId
        );
        await platform.connect();
        logger.info("Agent Connected: " + platform.agent.id);
        streamerOptions.iceServers = platform.agent.serviceCredentials
          .iceServers as RTCIceServer[];
        streamerOptions.forceRelay = clientOptions.ForceRelay;
        const models = await platform.getModels();
        setAvailableModels(models);
        logger.debug("Available models", models);
      } catch (err) {
        logger.error(err);
      }
    }
  }, [clientOptions]);

  useEffect(() => {
    console.log(Controller);
  });
  useEffect(() => {
    if (availableModels?.length) {
      const selectedModels = availableModels.filter(function (
        model: ModelDefinition
      ): boolean {
        if (clientOptions.ModelId === model.id) {
          // If there is a version specified and we encounter it
          if (
            clientOptions.Version &&
            clientOptions.Version === model.version
          ) {
            return true;
          }
          // If there is no version specified and we find the primary version
          if (!clientOptions.Version && model.active) {
            return true;
          }
        }
        return false;
      });
      if (selectedModels?.length) {
        setModelDefinition(selectedModels[0]);
      } else {
        setModelDefinitionUnavailable(true);
      }
    }
  }, [availableModels]);

  const launchRequestOptions: LaunchRequestOptions = {
    regionOverride: query["regionOverride"] as string,
  };
  const [status, launchRequest, queueLaunchRequest] = useLaunchRequest(
    // @ts-ignore
    platform,
    modelDefinition,
    launchRequestOptions
  );
  const [streamerStatus, emitter, videoStream, audioStream, messageSubject] =
  // @ts-ignore
    useStreamer(platform, launchRequest, streamerOptions);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (streamerStatus === StreamerStatus.Failed) {
      platform.disconnect();
    }
  }, [streamerStatus]);

  if (audioStream) {
    audio.srcObject = audioStream;
  }

  const launch = async () => {
    setLoading(true);
    audio.load();

    if (clientOptions.LaunchType !== "local") {
      await queueLaunchRequest();
    }
  };

  // Log status messages
  useEffect(() => {
    logger.info("Status", status, streamerStatus);
  }, [status, streamerStatus]);

  // Subscribe to game messages
  useEffect(() => {
    const subscription = messageSubject.subscribe(
      (value: string) => {
        logger.info("Message: " + value);
      },
      (err: any) => {
        logger.error(err);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [messageSubject]);

  // Notify user of missing or errors in configuration
  if (!clientOptions.isValid()) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p>
          Your client has one or more configuration errors. Please consult the{" "}
          <a href="https://www.npmjs.com/package/@pureweb/cra-template-pureweb-client">
            {" "}
            README{" "}
          </a>{" "}
          for details on how to configure the client template.
        </p>
      </div>
    );
  }

  if (modelDefinitionUnavailable) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span>The model that you have requested does not exist</span>:
      </div>
    );
  }

  // Begin connection
  if (streamerStatus === StreamerStatus.Disconnected) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2>Disconnected from stream</h2>
      </div>
    );
  }

  if (streamerStatus === StreamerStatus.Failed) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2>Failure during stream</h2>
        <h2>Please refresh to request a new session</h2>
      </div>
    );
  }

  if (streamerStatus === StreamerStatus.Withdrawn) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2>Streamer contribution withdrawn</h2>
      </div>
    );
  }

  if (loading) {
    return (
      <EmbeddedView
        VideoStream={videoStream}
        StreamerStatus={streamerStatus as StreamerStatus}
        LaunchRequestStatus={status}
        // @ts-ignore
        InputEmitter={emitter}
        UseNativeTouchEvents={clientOptions.UseNativeTouchEvents!}
        UsePointerLock={clientOptions.UsePointerLock!}
        PointerLockRelease={clientOptions.PointerLockRelease!}
      />
    );
  } else if (
    (clientOptions.LaunchType !== "local" && !availableModels) ||
    availableModels?.length
  ) {
    return (
      <LaunchPanel
        logoSrc={client.logo}
        description={client.description}
        subheading={client.subheading}
        title={client.title}
        isLoading={clientOptions.LaunchType !== "local" && !availableModels}
        onPlayButtonClick={launch}
      />
    );
  } else if (clientOptions.LaunchType !== "local" && !availableModels?.length) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2>No models are currently available in this environment.</h2>
      </div>
    );
  }
};

const AppWrapper: React.FC = () => {
  return System.IsBrowserSupported() ? (
    <App />
  ) : (
    <div className="ui red segment center aligned basic">
      <h2 className="header">Your browser is currently unsupported</h2>
    </div>
  );
};

export default AppWrapper;
